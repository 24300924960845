.ant-input-number-input {
  text-align: right !important;
}

.highlight-row {
  background-color: #e6f7ff; /* Light blue background for first row */
}

.ant-input-number-input{
  font-size: 2rem !important;
  height: 4rem !important;
}

.btn-green {
  background-color: #52c41a;
  border-color: #52c41a;
  color: white;
}

.btn-green:hover {
  background-color: #389e0d !important;
  border-color: #389e0d;
  color: white;
}

.btn-extra {
  margin-left: 10px;
  padding: 40px
}